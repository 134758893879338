<template>
	<div card class="p-5 bg-white">
		<div card-body>
			<h3 class="mb-5 font-weight-bold text-dark">Reports will be emailed to:</h3>
			<div class="mb-10" style="display: flex; align-items: center">
				<div class="font-weight-bold text-dark" style="font-size: 1.1rem">
					{{ adminAccount.data.email }}
				</div>
				<span
					class="svg-icon svg-icon-sm svg-icon-primary"
					v-b-tooltip="'Edit Account Info'"
					@click="gotoInfo()"
				>
					<inline-svg src="/media/svg/icons/Design/Edit.svg" />
				</span>
			</div>
			<div class="row">
				<div class="col-xl-6">
					<h4 class="mb-5 font-weight-bold text-dark">Report Frequency</h4>

					<b-form-checkbox
						disabled
						class="checkboxes"
						v-model="reports.daily"
						value="true"
						unchecked-value="false"
					>
						Daily
					</b-form-checkbox>
					<b-form-checkbox
						disabled
						class="checkboxes"
						v-model="reports.weekly"
						value="true"
						unchecked-value="false"
					>
						Weekly
					</b-form-checkbox>
					<b-form-checkbox
						disabled
						class="checkboxes"
						v-model="reports.monthly"
						value="true"
						unchecked-value="false"
					>
						Monthly
					</b-form-checkbox>
					<b-form-checkbox
						disabled
						class="checkboxes"
						v-model="reports.yearly"
						value="true"
						unchecked-value="false"
					>
						Yearly
					</b-form-checkbox>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
	data() {
		return { reports: {} };
	},
	methods: {
		setReports() {
			this.reports = JSON.parse(JSON.stringify(this.adminAccount.data.reports));
		},
		gotoInfo() {
			this.$emit('info');
		},
		save(){
			
		}
	},
	computed: {
		...mapGetters(['adminAccount']),
	},
	mounted() {
		if(this.adminAccount.data.reports) {
			this.setReports();
		}
	},
};
</script>

<style>
</style>